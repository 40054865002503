@tailwind base;
@tailwind components;
@tailwind utilities;

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: inherit;
  background-image: red;
} */
